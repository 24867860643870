<template>
  <v-app>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
        >
          Project Detail
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
        >
          Proposal
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 3"
          step="3"
        >
          Payment Term
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 4"
          step="4"
        >
          Development Info
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 5"
          step="5"
        >
          Development Team
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="6">
          File
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <project-detail @next="nextStep" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <proposal @next="nextStep" @render="forceRerender" />
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: .5
            }"
            min-height="200"
            transition="fade-transition"
          >
            <payment-term v-if="renderComponent" @next="nextStep" />
          </v-lazy>
        </v-stepper-content>

        <v-stepper-content step="4">
          <development-info @next="nextStep" />
        </v-stepper-content>

        <v-stepper-content step="5">
          <development-team @next="nextStep"/>
        </v-stepper-content>

        <v-stepper-content step="6">
          <files />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-app>
</template>

<script>
import projectDetail from './projectDetail/Index.vue'
import paymentTerm from './paymentTerm/Index.vue'
import proposal from './proposal/Index.vue'
import developmentInfo from './developmentInfo/Index.vue'
import files from './files/Index.vue'
import developmentTeam from './developmentTeam/Index.vue'

export default {
  components: {
    projectDetail,
    paymentTerm,
    proposal,
    developmentInfo,
    files,
    developmentTeam
  },
  data: () => ({
    e1: localStorage.getItem('step'),
    isActive: false,
    renderComponent: true
  }),
  methods: {
    nextStep(item) {
      this.e1 = item
    },
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    }
  }
}
</script>
